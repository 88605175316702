import { Component, OnInit } from '@angular/core';
import {SubscriptionService} from '../shared/services/subscription.service';
import {DefaultUrlSerializer} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'mbnm-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  constructor(private subscriptionService: SubscriptionService, private location: Location) { }

  public listName = '';
  public status = 'IN_PROGRESS';
  public error: any;

  ngOnInit(): void {
    const urlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();
    const url = urlSerializer.parse(this.location.path());

    this.listName = url.queryParams.list === 'abandoned-carts' ? 'abandoned cart emails' : 'newsletter';
    this.subscriptionService
      .unsubscribe(url.queryParams.email, url.queryParams.list)
      .subscribe( () => {
        this.status = 'DONE';
      }, (result: any) => {
        this.status = 'ERROR';
        this.error = result.error;
      });
  }
}
