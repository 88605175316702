import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Router, NavigationEnd} from '@angular/router';

import { User } from '../shared/models/user';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import {CartService} from '../shared/services/cart.service';
import {YesNoDialogComponent} from '../yesno-dialog/yesno-dialog.component';
import {OrderService} from '../shared/services/order.service';
import {CmsService} from '../shared/services/cms.service';
import {isPlatformBrowser} from '@angular/common';
import {ChatService} from '../shared/services/chat.service';
import {ContactUsDialogComponent} from '../contact-us-dialog/contact-us-dialog.component';
import {MatMenuPanel, MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'mbnm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  recentlyOpenedMenu: MatMenuPanel;
  @HostListener('window:click', ['$event']) onKeypress() {
    this.menuTriggers && this.menuTriggers.forEach((trigger: MatMenuTrigger) => {
      if (trigger.menu !== this.recentlyOpenedMenu) {
        trigger.closeMenu();
      }
    });
  }

  shipPromoShown = false;
  isHome: boolean;
  menuItems = [];

  constructor(
    private router: Router,
    private cartService: CartService,
    private orderService: OrderService,
    private chatService: ChatService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cms: CmsService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activatedRouteRootNavLink = val.url.split('/')[1];
      }
    });

    this.cms
      .menu('primary')
      .then((menuItems) => {
        this.menuItems = menuItems;
        if (isPlatformBrowser(this.platformId)) {
          setTimeout( () => {
            if (this.menuTriggers) {
              this.menuTriggers.forEach(trigger => {
                trigger.menuOpened.subscribe(() => {
                  this.recentlyOpenedMenu = trigger.menu;
                  setTimeout( () => {
                    this.recentlyOpenedMenu = null;
                  });
                  this.menuTriggers.forEach(t => {
                    if (t !== trigger) {
                      t.closeMenu();
                    }
                  });
                })
              })
            }
          }, 0);
        }
      }, (err) => {
      });

    CartService.shippingPromoRequested.subscribe( () => {
      this.shipPromoShown = true;
      const dialogRef = this.dialog.open(YesNoDialogComponent);
      const instance = dialogRef.componentInstance;

      instance.data = {
        title: this.dynamicTextBlocks.delivery_promise_title,
        message: this.dynamicTextBlocks.delivery_promise_content,
        closeMethod: 'x'
      };
    })
  }

  @Input() currentCart: any;
  @Input() currentUser: User;
  @Input() dynamicTextBlocks: any;

  public activatedRouteRootNavLink: string;
  public chatStatus = 'NOT_INITIALIZED';
  public startChatOrAwayFormDeferred = false;

  ngOnInit() {
    ChatService.chatStatusChanged.subscribe( (status) => {
      // case where its available in new version means its always available (no toggling)
      if (this.chatStatus !== 'AVAILABLE' || status === 'AVAILABLE') {
        this.chatStatus = status;
      }
      if (this.startChatOrAwayFormDeferred) {
        this.startChatOrAwayFormDeferred = false;
        this.startChatOrAwayForm();
      }
    });
  }

  showSignInDialog() {
    const dialog = this.dialog.open(UserDialogComponent,{disableClose:false});
    dialog.componentInstance.configs = {
      closeMethod : 'x'
    };
  }

  get onAccountPage() {
    return this.router.url.includes('account')  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  showEditOrderDialog() {
    const dialogRef = this.dialog.open(YesNoDialogComponent);
    const instance = dialogRef.componentInstance;

    instance.data = {
      title: 'Changing Existing Order',
      message: '<p>Order #' + this.currentCart.orderConfirmationNumber + ' has been <strong>placed on hold</strong> while you make your changes.</p>' +
               '<p>If you do not wish to make any changes, click "Discard Changes" to undo all changes & release your order from hold.</p>',
      yesButtonText: 'Discard Changes',
      noButtonText: 'Continue'
    };

    dialogRef.afterClosed().subscribe((result:boolean) => {
      if (result) {
        const orderNumber = this.currentCart.orderConfirmationNumber;
        this.orderService.cancelEditOrder(orderNumber)
          .subscribe(order => {
            this.cartService.getCart().subscribe(() => {});
            this.router.navigateByUrl('/account/order/' + orderNumber);
          });
      }
    });

  }

  /* TODO: FIGURE OUT WHERE THIS LOGIC RELATED TO SHOWING NEWS LETTER GOES
  (was in ngOnInit before)
  if (this.shouldShowNewsletterDialog()) {
    this.dialog.open(NewsletterDialogComponent);
    localStorage.setItem('newsletter-dialog-shown', 'true');
    this.firstLoad = false;
  }

  private shouldShowNewsletterDialog(): boolean {
    return false; // disabled for now
    return (!this.currentUser || this.currentUser.isAnonymous) && this.firstLoad === true &&
           !this.hasDialogShown();
  }

  private hasDialogShown(): boolean {
    return localStorage.getItem('newsletter-dialog-shown') === 'true';
  }
  */

  openShipPromo() {
    CartService.shippingPromoRequested.next(true);
  }

  startChatOrAwayForm() {
    if (this.chatStatus === 'NOT_INITIALIZED') {
      this.startChatOrAwayFormDeferred = true;
      this.chatService.initialize();
    } else {
      if (this.chatStatus.startsWith('AVAILABLE')) {
        this.chatService.startChat();
      } else {
        const dialogRef = this.dialog.open(ContactUsDialogComponent);
        dialogRef.componentInstance.chatLoadFailed = this.chatStatus === 'LOAD_FAILED';
      }
    }
  }

  @ViewChildren('menuTrigger') menuTriggers: QueryList<MatMenuTrigger>;
}
