<header>
  <div class="main-header fx-layout-row fx-align--space-between-x fx-align--x-center fx-gap--16px">

    <div class="logo fx-flex-auto">
      <a routerLink="" (click)="isHome=true">
        <img src="/assets/images/logo.png" alt="Matboard and More Logo">
      </a>
    </div>

    <div class="links-and-buttons">
      <div class="nav-links">
        <nav mat-tab-nav-bar [tabPanel]="tabPanel">
          @for (menuItem of menuItems; track menuItem) {
            <mat-menu #subMenu="matMenu" xPosition="before" yPosition="below" hasBackdrop="false" >
              <button mat-menu-item
                      *ngFor="let subMenuItem of menuItem.children"
                      [routerLink]="subMenuItem.url">
                <mat-icon *ngIf="subMenuItem.icon" [svgIcon]="subMenuItem.icon"></mat-icon>
                <span>{{subMenuItem.title | uppercase}}</span>
              </button>
            </mat-menu>

            <a mat-tab-link
               [ngClass]="{'hide-on-small-screens': menuItem.hide_on_small_screens, 'highlight': menuItem.highlight}"
               [routerLink]="menuItem.children.length > 0 ? null : (menuItem.url ? menuItem.url : null)"
               [matMenuTriggerFor]="menuItem.children.length > 0 ? subMenu : null"
               #menuTrigger="matMenuTrigger"
               [active]="menuItem.url.indexOf(activatedRouteRootNavLink) === 0 && activatedRouteRootNavLink !== ''"
               (click)="isHome = false">
              {{menuItem.title | uppercase}}
              <small>&nbsp;</small>
              <mat-icon svgIcon="chevron-down" *ngIf="menuItem.children.length > 0">
              </mat-icon>
            </a>
          }
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      </div>

      <div class="nav-buttons fx-layout-row fx-align--end-x fx-align--x-center fx-gap--4px">
        <button mat-icon-button color="secondary" class="shipping-button" [ngClass]="{glowing: !shipPromoShown && (!currentCart || !currentCart.orderConfirmationNumber)}" (click)="openShipPromo()">
          <label *ngIf="dynamicTextBlocks?.shipping_label && (!currentCart || !currentCart.orderConfirmationNumber)">
            {{ dynamicTextBlocks.shipping_label}}
          </label>
          <mat-icon svgIcon="truck"></mat-icon>
        </button>

        <a mat-icon-button color="secondary" class="icon order-edit glowing" *ngIf="currentCart && currentCart.orderConfirmationNumber" (click)="showEditOrderDialog()">
          <label class="below">
            <div style="white-space: nowrap">Order Changes In Progress</div>
          </label>
          <mat-icon svgIcon="progress-check"></mat-icon>
        </a>

        <a mat-icon-button color="secondary" class="icon chat-button" (click)="startChatOrAwayForm()">
          <mat-icon aria-hidden="false" svgIcon="chat" matBadgeSize="large" [matBadge]="chatStatus === 'AVAILABLE' ? 'live' : null"></mat-icon>
        </a>

        <a mat-icon-button color="secondary" [routerLink]="currentCart && currentCart.orderConfirmationNumber ? '/order' : '/cart'" class="icon">
          <mat-icon aria-hidden="false" matBadgeSize="large" svgIcon="cart-outline" [matBadge]="currentCart && currentCart.orderlines && currentCart.orderlines.length > 0 ? currentCart.orderlines.length : null" matBadgeColor="primary"></mat-icon>
        </a>
        <a mat-icon-button color="secondary" *ngIf="!currentUser || currentUser.isAnonymous" (click)="showSignInDialog()" class="icon">
          <mat-icon svgIcon="signin"></mat-icon>
        </a>
        <a mat-icon-button color="secondary" *ngIf="currentUser && !currentUser.isAnonymous" (click)="navigateTo('/account')"  color="secondary" class="icon" [ngClass]="{icon: true}">
          <mat-icon svgIcon="account-multi" *ngIf="currentUser.impersonating" color="warn"></mat-icon>
          <mat-icon svgIcon="account" *ngIf="!currentUser.impersonating" color="secondary"></mat-icon>
        </a>

        <!-- used on mobile -->
        <div class="nav-dropdown">
          <a mat-icon-button [matMenuTriggerFor]="menu" class="icon">
            <mat-icon svgIcon="menu"></mat-icon>
          </a>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let menuItem of menuItems">
              <mat-menu #subMenu="matMenu" xPosition="before" yPosition="below">
                <button mat-menu-item
                        *ngFor="let subMenuItem of menuItem.children"
                        [routerLink]="subMenuItem.url">
                  <mat-icon *ngIf="subMenuItem.icon" [svgIcon]="subMenuItem.icon"></mat-icon>
                  <span>{{subMenuItem.title | uppercase}}</span>
                </button>
              </mat-menu>
              <!-- menu items are either links to pages or triggers for sub menus  -->
              <button mat-menu-item
                      [routerLink]="menuItem.children.length > 0 ? null : menuItem.url"
                      [matMenuTriggerFor]="menuItem.children.length > 0 ? subMenu : null">
                <mat-icon *ngIf="menuItem.icon" [svgIcon]="menuItem.icon"></mat-icon>

                <div>
                  {{menuItem.title | uppercase}}
                  <mat-icon *ngIf="menuItem.children.length > 0" svgIcon="chevron-down"></mat-icon>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="banner" [innerHTML]="dynamicTextBlocks?.banner | sanitizer: 'html'"> </div>
</header>
