import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {WINDOW} from './window.service';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  subscriptionBaseUrl = environment.baseUrls().api + 'subscriptions/';

  constructor(private httpClient: HttpClient,
              @Inject(WINDOW) private window: Window,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  unsubscribe(email: string, list: string): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpClient
        .post(this.subscriptionBaseUrl + 'unsubscribe', {email: email, list: list});
    } else {
      return of(null);
    }
  }
}
